import React, {useState, useEffect} from 'react'
import './../css/machine_registration.css';
import {withRouter} from 'react-router-dom'

import { connect } from 'react-redux'
import CP_Next_3 from './../../media/3_CP_Next.png'
import SerialNumber_position from './../../media/SerialNumber_position.png'
import logo from './../../media/logo.svg'
import { addField } from '../../redux/actions';

import Language from './../../components/common/language/lang'


import controlDataMReg from './../../components/machineRegistration/MRegData_control.js'
import TextInput from './../../components/machineRegistration/eng/input'
import SelectNazione from './../../components/common/dropDownMenu/eng/select_nazione'

/*trasformare in coordinate*/
/* import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBA9jKr1Eluut8MJdok-CrKNDLs_0JdHv0");
Geocode.setLanguage("en"); */
/*trasformare in coordinate*/

const MachineRegistration = (props) => {
   
   const changePage = slug => {
      props.history.push(slug+"-en")
   }

   const [FormData, setFormData] = useState({
      NomeMacchina: props.form.NomeMacchina,
      SerialNumber: props.form.SerialNumber,
      AssetId: props.form.AssetId,
      PosizioneGPSMacchina: props.form.PosizioneGPSMacchina,
      Indirizzo: "",
      Nazione: "",
      Citta: "",
      Provincia: "",
      CAP: ""
   })

   const [click,setClick] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         if(click){
            setClick((prev)=>prev=false)
         }
      }, 500)      
   }, [FormData])

   useEffect(() => {
      [nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)  
   }, [nomeCorretto,serialCorretto,assetCorretto])

   const submitMReg = () => {
      props.add("NomeMacchina",FormData.NomeMacchina);
      props.add("SerialNumber",FormData.SerialNumber);
      props.add("AssetId",FormData.AssetId);
      
      /* 

      Geocode.fromAddress("Eiffel Tower").then(
         (response) => {
           const { lat, lng } = response.results[0].geometry.location;
           gpsPosition = lat+lng;
         },
         (error) => {
           console.error(error);
         }
       );

      props.add("PosizioneGPSMacchina",gpsPosition); */
      
      var gpsPosition;
      var indirizzoPosition = FormData.Indirizzo,nazionePosition = FormData.Nazione,cittaPosition = FormData.Citta,provinciaPosition = FormData.Provincia,capPosition = FormData.CAP;

      indirizzoPosition = indirizzoPosition.replace(/\s/g, '+');
      nazionePosition = nazionePosition.replace(/\s/g, '+');
      cittaPosition = cittaPosition.replace(/\s/g, '+');
      provinciaPosition = provinciaPosition.replace(/\s/g, '+');
      capPosition = capPosition.replace(/\s/g, '+');
      
      gpsPosition=[indirizzoPosition,nazionePosition,cittaPosition,provinciaPosition,capPosition]
      gpsPosition = gpsPosition.join('+')
      
      gpsPosition = "https://www.google.it/maps/place/"+gpsPosition;
      gpsPosition = gpsPosition.replace(/\s/g, '+');

      props.add("PosizioneGPSMacchina",gpsPosition)

      var indirizzoMacchina;

      indirizzoMacchina=[FormData.Indirizzo,FormData.Nazione,FormData.Citta,FormData.Provincia,FormData.CAP]
      indirizzoMacchina = indirizzoMacchina.join('-')

      props.add("IndirizzoMacchina",indirizzoMacchina)

      changePage('/thanks-page');
   }

   var nomeCorretto = false, serialCorretto = false, assetCorretto = false;

   var pressInvio = (event) => {

      [nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)
      
      if (event.keyCode === 13){
         if (nomeCorretto && serialCorretto && assetCorretto){
            submitMReg()
         }else{
            setClick((prev)=>prev=true)
         }
      }
   }

   return (
      <div className="m_form" onKeyDown={(event)=>pressInvio(event)}>
         <div className="first_block">
            <a href="https://www.irinoxprofessional.com/eng/" target="_blank"><img className="logo" alt="logo" src={logo}/></a>
            <div className="langMReg"><Language  color={"#002445"}/></div>
         </div>
            
         <div className="twoblocksMReg">
            <div className="formMReg">
               <div className="formTitleMReg">INFORMATION ABOUT YOUR NEW HOLDING CABINET</div>
               <div className="columnsMReg">
                  
                  {[nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)}
                  <div className="textInput "><TextInput fieldErrorMReg={!nomeCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           NomeMacchina: valore
                        }))}
                        default_value={props.form.NomeMacchina}
                     placeholder="Enter your holding cabinet's name" label="NAME*"/></div> 

                  <div id="input_finto" className="textInput "><TextInput/></div>

                  {[nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)} 
                  <div className="textInput "><TextInput fieldErrorMReg={!serialCorretto&&click ?  (true) : (false)}  
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           SerialNumber: valore
                        }))}
                        default_value={props.form.SerialNumber}
                     placeholder="Enter your holding cabinet's serial number" label="SERIAL NUMBER*" info={true} immagineHover={SerialNumber_position}/></div>
                  
                  {[nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)} 
                  <div className="textInput "><TextInput fieldErrorMReg={!assetCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           AssetId: valore
                        }))}
                        default_value={props.form.AssetId}
                     placeholder="Enter your holding cabinet's asset id" label="ASSET ID*" info={true} testoHover={true}/></div>
                  
                  <div className="textInput "><TextInput 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Indirizzo: valore
                        }))}
                     placeholder="Enter your holding cabinet's address" label="ADDRESS"/></div>
                  
                  <div className="textInput "><SelectNazione 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Nazione: valore
                        }))}
                     placeholder="Select your holding cabinet's country" label="COUNTRY"/></div>
                  
                  <div className="textInput "><TextInput 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Citta: valore
                        }))}
                     placeholder="Enter your holding cabinet's town" label="TOWN"/></div>
                  
                  <div className="coupleAddr"> 
                     <div className="textInput"><TextInput 
                        onChange={(valore)=>
                           setFormData((prev) => ({
                              ...prev,
                              Provincia: valore
                           }))}
                        placeholder="Enter the province" label="PROVINCE" couple={true}/></div>
                     
                     <div className="textInput"><TextInput 
                        onChange={(valore)=>
                           setFormData((prev) => ({
                              ...prev,
                              CAP: valore
                           }))}
                        placeholder="Enter the zip code" label="ZIP CODE" couple={true}/></div>
                  </div>
               </div>
               <div className="tipMReg">(* mandatory fields)</div>
               <div className="buttonsMReg"> 
               <div className="pointer empty_button buttonMReg" onClick={() => {props.history.push("/en")}}>Go back to the home page</div>

                  {[nomeCorretto,serialCorretto,assetCorretto]=controlDataMReg(FormData)}               
                  <div className="pointer filled_button" onClick={() => { nomeCorretto && serialCorretto && assetCorretto ? submitMReg() : (setClick((prev)=>prev=true))}}>Register holding cabinet</div>
               </div>
               
            </div>
            <img src={CP_Next_3} className="Cp3MReg"/>
         </div>
      </div>
   )
}

const mapActionToProps = {
   add: (field,fieldValue) => addField(field,fieldValue)
}

export default connect(               
   state => { 
      return {form: state.form}
  },
   mapActionToProps
) (withRouter(MachineRegistration))