import { ADD_FIELD} from "./actionsType";

const initalState = {
   form:
   {
      NomeUtente: "",
      CognomeUtente: "",
      Azienda: "",
      Email: "",
      IndirizzoAzienda: "",
      NazioneAzienda: "",
      CittaAzienda: "",
      ProvinciaAzienda: "",
      CAPAzienda: "",
      Telefono: "",
      NomeMacchina: "",
      SerialNumber: "",
      AssetId: "",
      PosizioneGPSMacchina: "",
      IndirizzoMacchina: ""
   }         
}

export default function (state = initalState, action) {

   var arr = JSON.parse(JSON.stringify(state.form))

   switch (action.type){
      case ADD_FIELD:
         arr[action.field]=action.field_value
         return{
            ...state,
            form: arr
         }

      default:
         return state
   }
}