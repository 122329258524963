import React, { useState } from "react"

const SelectInput = ({onChange,placeholder,label,fieldErrorMReg}) => {

   var settore;

   return(
      <>
         <div>
            <div className="labelcss">{label}</div>
         </div>

         <select className={fieldErrorMReg ? ("fieldErrorMReg inputcss"): ("inputcss")} type="text"
               placeholder={"Seleziona "+placeholder}
               value={settore}
               onChange={(event) => onChange(event.currentTarget.value)}
               required>
            <option value="">Select the sector</option>
            <option value="Pasticceria">Pastry</option>
            <option value="Gelateria">Ice-cream/gelato maker</option>
            <option value="Panificazione">Bakery</option>
            <option value="Ristorazione">Gastronomy</option>
            <option value="Catering">Mass Catering</option>
            <option value="Altro">Other</option>
         </select>
      </>
   )
   
}

export default SelectInput