var result = [true,true,true];

export default function (FormData){
   result=[true,true,true];

   var correctSerialNumber = false;
   /*Controllo Serial Number*/
   var year = FormData.SerialNumber.slice(0, 2);

   var month = FormData.SerialNumber.slice(2, 4);

   var fiveDigits = FormData.SerialNumber.slice(4, 9);

   var mChar = FormData.SerialNumber.slice(9, 10);
   
   year = [...year];
   month = [...month];  //copia per valore nel numero da tradurre
   fiveDigits = [...fiveDigits];

   year = year.join('');
   month = month.join(''); //unire i due char
   fiveDigits = fiveDigits.join('');
   
   year = Number(year)
   month = Number(month) //tradurre in numero
   fiveDigits = Number(fiveDigits)

   if((year === 99 || (year >= 0 && year <= 21)) && (month >= 1 && month <= 12) && (fiveDigits > 0) && (mChar === 'M') && (FormData.SerialNumber.length === 10)){      
      correctSerialNumber = true;
   }
   /*Controllo Serial Number*/

   /*Controllo Asset id*/
   var correctAssetID = false;
   var lunghezzaAI = FormData.AssetId.length;
   var macAdd = FormData.AssetId.slice(0,12);
   var mac1 = FormData.AssetId.slice(0,1);
   var hex = /[0-9A-Fa-f]{12}/g;

   if (FormData.AssetId.slice(lunghezzaAI-4, lunghezzaAI) === "_001" && hex.test(macAdd) && lunghezzaAI === 16 && mac1 != 0){
      correctAssetID = true;
   }
   /*Controllo Asset id*/

   if (FormData.NomeMacchina === "" || FormData.SerialNumber === "" || FormData.AssetId === "" || FormData.NomeMacchina === " " || FormData.SerialNumber === " " || FormData.AssetId === " " || !correctSerialNumber || !correctAssetID){
      if(FormData.NomeMacchina === "" || FormData.NomeMacchina === " "){
         result[0]=false;
      }
      if(FormData.SerialNumber === "" || FormData.SerialNumber === " " || !correctSerialNumber){
         result[1]=false;
      }
      if(FormData.AssetId === "" || FormData.AssetId === " " || !correctAssetID){
         result[2]=false;
      }
      return result;
   }
   return result;
}