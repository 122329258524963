import React from 'react'
import './layout_wrapper.css';
import Languages from './language/lang';

const LayoutWrapper = (props) => {
   return (
      <div className="desktop">
         {/* <Languages/> */}
         {props.children}
      </div>
   )
}
export default LayoutWrapper