import React from 'react'
import './footer.css';

import logo from './../../media/logo.png'
import { ReactComponent as Assistence } from './../../media/Icons/operator.svg'
import { ReactComponent as Contact } from './../../media/Icons/contact.svg'
import { ReactComponent as Service } from './../../media/Icons/tools.svg'
import { ReactComponent as Fb_icon } from './../../media/Icons/fb_icon.svg'
import { ReactComponent as Ln_icon } from './../../media/Icons/ln_icon.svg'
import { ReactComponent as Ig_icon } from './../../media/Icons/ig_icon.svg'
import { ReactComponent as Yt_icon } from './../../media/Icons/yt_icon.svg'


const Footer = () => {
   return (
      <div className="footer">
         <div className="flex_row">
            <div className="flex_col_left">
               <a href="https://www.irinoxprofessional.com/ita/" target="_blank"><img className="logo" alt="logo" src={logo}/></a>
               <div className="text mar_top">
                  Irinox nasce nel 1989 in Italia, a Corbanese ed è riconosciuto in tutto il mondo come lo specialista degli abbattitori e surgelatori rapidi di temperatura. La produzione, Made in Italy, è interamente realizzata presso i propri stabilimenti, in un’area di 15.000 m<sup>2</sup> alle porte di Treviso.
               </div>
               <div className="text bold_txt">IRINOX S.p.A.</div>
               <div className="text">
                  Via Caduti nei Lager, 1<br/>
                  31015 Conegliano (TV) - Italy<br/>
                  Tel. +39 0438 2020<br/>
                  Fax +39 0438 2023
               </div>
               <a target="_blank" href="https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"><div className="bold_txt white_btn pointer">MOSTRA SULLA MAPPA</div></a>
            </div>
            <div className="flex_col_right">
               <a className="blocks" target="_blank" href="https://www.irinoxprofessional.com/ita/richiedi-assistenza"><div className="bold_txt help_btn pointer contatti">
                     <div className="flex_row_icons">
                        <div className="fix_assistenza">ASSISTENZA</div>
                        <Assistence className="icon_assistance"/>
                     </div>
                  </div></a>
               <a className="blocks" target="_blank" href="https://www.irinoxprofessional.com/ita/service"><div className="bold_txt help_btn pointer contatti">
                     <div className="flex_row_icons">
                        <div>SERVICE</div>
                        <Contact className="icons"/>
                     </div>
               </div></a>
               <a className="blocks" target="_blank" href="https://www.irinoxprofessional.com/ita/contatti"><div className="bold_txt help_btn pointer contatti">
                     <div className="flex_row_icons">
                        <div>CONTATTACI</div>
                        <Service className="icons"/>
                     </div>
               </div></a>
               <div className="flex_row_social blocks">
                  <a target="_blank" href="https://www.facebook.com/irinoxprofessional">
                     <Fb_icon className="social_icon" id="fbIcon"/>
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/showcase/18135187/admin/updates/">
                     <Ln_icon className="social_icon" id="lnIcon"/>
                  </a>
                  <a target="_blank" href="https://www.instagram.com/irinox_professional/">
                     <Ig_icon className="social_icon" id="igIcon"/>
                  </a>
                  <a target="_blank" href="https://www.youtube.com/user/Irinoxprofessional">
                     <Yt_icon className="social_icon" id="ytIcon"/>
                  </a>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Footer