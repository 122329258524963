var result = [true,true,true,true,true,true,true,true,true,true];
var mailFlag=false;
var phoneFlag = false;
var capFlag = false;

export default function (FormData){

   result=[true,true,true,true,true,true,true,true,true,true];
   var mailFlag=false;
   var phoneFlag = false;
   var capFlag = false;

   /*mail*/
   var atpos = FormData.Email.indexOf("@");
   var dotpos = FormData.Email.lastIndexOf(".");
   if (atpos<1 || dotpos<atpos+2 || dotpos+2>=FormData.Email.length)
   {
      mailFlag = true;
   }
   /*mail*/

   /*phone number*/
   var number = FormData.Telefono;
   var regPhn = new RegExp('^\\d+$');

   if (!regPhn.test(number) || number.length > 15 || number.length < 5){
      phoneFlag = true;
   }
   /*phone number*/

   /*cap*/
   if (isNaN(FormData.CAPAzienda) || FormData.CAPAzienda === "" || FormData.CAPAzienda === " "){
      capFlag = true;
   }
   /*cap*/

   if (FormData.NomeUtente === "" || FormData.CognomeUtente === "" || FormData.Azienda === "" || mailFlag || FormData.IndirizzoAzienda === "" || FormData.NazioneAzienda === "" || FormData.CittaAzienda === "" || FormData.ProvinciaAzienda === "" || phoneFlag || capFlag || FormData.NomeUtente === " " || FormData.CognomeUtente === " " || FormData.Azienda === " " || FormData.IndirizzoAzienda === " " || FormData.NazioneAzienda === " " || FormData.CittaAzienda === " " || FormData.ProvinciaAzienda === " "){
      if(FormData.NomeUtente === "" || FormData.NomeUtente === " "){
         result[0]=false;
      }
      if(FormData.CognomeUtente === "" || FormData.CognomeUtente === " "){
         result[1]=false;
      }
      if(FormData.Azienda === "" || FormData.Azienda === " "){
         result[2]=false;
      }
      if(mailFlag){
         result[3]=false;
      }
      if(FormData.IndirizzoAzienda === "" || FormData.IndirizzoAzienda === " "){
         result[4]=false;
      }
      if(FormData.NazioneAzienda === "" || FormData.NazioneAzienda === " "){
         result[5]=false;
      }
      if(FormData.CittaAzienda === "" || FormData.CittaAzienda === " "){
         result[6]=false;
      }
      if(FormData.ProvinciaAzienda === "" || FormData.ProvinciaAzienda === " "){
         result[7]=false;
      }
      if(capFlag){
         result[8]=false;
      }
      if(phoneFlag){
         result[9]=false;
      }
      return result;
   }
   return result;
}