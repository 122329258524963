import React, { useEffect, useState } from "react"

import { ReactComponent as InfoLogo } from './../../../media/Icons/info.svg'

const TextInput = ({onChange,placeholder,label,couple,info,fieldErrorMReg,immagineHover,testoHover,default_value}) => {

   const [hovered, setHovered] = useState(false);

   var onMouseClick = (e) => {
      if(e.target !== e.currentTarget){
         return
      }
      else{
         setHovered(!hovered);
      }      
   };

   return(
      <>
         <div>
            <div className="labelcss">{label}</div>

            {info ? (<span onClick={(e) => onMouseClick(e)}><InfoLogo onClick={(e) => onMouseClick(e)} className="infoLogo pointer"/></span>) : null}

         </div>   
         {hovered && !testoHover ? 
         <div onClick={(e) => onMouseClick(e)} className="boxImgHover">
            <div className="imgHover"><img src={immagineHover}/></div>
         </div> : null}

         
         <input className={fieldErrorMReg ? ("fieldErrorMReg inputcss"): ("inputcss")}type="text"
            placeholder={placeholder}
            defaultValue={default_value}
            onChange={(event) => onChange(event.target.value)}
         />
         {hovered && testoHover ?
         <div onClick={(e) => onMouseClick(e)} className="boxImgHover">
            <div className="textHover">
               Enter the <span id="boldAI">Asset Id</span> number provided when your holding cabinet has been installed.
            </div>
         </div> : null}
      </>
   )
   
}

export default TextInput