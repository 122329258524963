var result = [true,true];//mail,error

export default function (FormData){
   result = [true,true];
   var atpos = FormData.mail.indexOf("@");
   var dotpos = FormData.mail.lastIndexOf(".");
   if (atpos<1 || dotpos<atpos+2 || dotpos+2>=FormData.mail.length)
   {
      result[0]=false;
   }

   var number = FormData.phoneNumber;
   var reg = new RegExp('^\\d+$');

   if (!reg.test(number) || number.length > 15 || number.length < 5){
      result[1]=false;
   }
   if (result[0]&& result[1]){
   }

   return result;
}