import React, {useEffect} from 'react'
import './../css/thanks_page.css';
import { connect } from 'react-redux'
import { addField } from '../../redux/actions';

import { ReactComponent as Fb_icon } from './../../media/Icons/fb_icon.svg'
import { ReactComponent as Ln_icon } from './../../media/Icons/ln_icon.svg'
import { ReactComponent as Ig_icon } from './../../media/Icons/ig_icon.svg'
import { ReactComponent as Yt_icon } from './../../media/Icons/yt_icon.svg'
import { withRouter } from 'react-router';

const ThanksPage = (props) => {


   var sendEmail = () => {
      window.emailjs.send('service_tw1m7h8', 'template_nhkk1eu', props.form) //use your Service ID and Template ID   irinox
      .then(function(response) {
         console.log('SUCCESS!', response.status, response.text);
         clearFormData()
      }, function(error) {
         console.log('FAILED...', error);
      });
   }

   useEffect(()=>{
      sendEmail()
  }, [])
   
   

   var clearFormData = () => {
      window.localStorage.clear()
      //window.location.reload();
      //{console.log(props.form)}
   }

   return (
      <div className="thx_page">
         <div className="thx_box flex_col_thx">
            <div className="thx_title block_thx">
               Benvenuto in C-CLOUD!
            </div>   

            <div className="light_text block_thx">
               <div className="grazie">Grazie {props.form.NomeUtente} per aver registrato il tuo nuovo conservatore!</div>
               
               <div>Entro 72 ore riceverai via e-mail le credenziali per la tua area personale nel nostro cloud!</div>
            </div>

            <a className="block_thx" href="https://www.irinoxprofessional.com/ita/" target="_blank"><div className="empty_button">Visita il nostro sito</div></a>
            
            <div className="flex_col_thx light_text block_thx">
               Oppure seguici nei nostri social:
               <div className="flex_row_social_icons">
                  <a className="icon_thx" target="_blank" href="https://www.facebook.com/irinoxprofessional">
                     <Fb_icon className="social_icon_thx" id="fbIcon"/>
                  </a>
                  <a className="icon_thx" target="_blank" href="https://www.linkedin.com/showcase/18135187/admin/updates/">
                     <Ln_icon className="social_icon_thx" id="lnIcon"/>
                  </a>
                  <a className="icon_thx" target="_blank" href="https://www.instagram.com/irinox_professional/">
                     <Ig_icon className="social_icon_thx" id="igIcon"/>
                  </a>
                  <a className="icon_thx" target="_blank" href="https://www.youtube.com/user/Irinoxprofessional">
                     <Yt_icon className="social_icon_thx" id="ytIcon"/>
                  </a>
               </div>
               Buona permanenza nella famiglia IRINOX!
            </div>
         </div>
         
      </div>
   )
}

const mapActionToProps = {
   add: (field,fieldValue) => addField(field,fieldValue)
}

export default connect(               
   state => { 
      return {form: state.form}
  },
   mapActionToProps
) (withRouter(ThanksPage))