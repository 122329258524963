import React from 'react'
import './lang.css';
import ita_flag from './../../../media/Icons/ita_flag.svg'
import usa_flag from './../../../media/Icons/usa_flag.svg'
import { withRouter } from 'react-router';

const Languages = (props) => {

   const changePage = slug => {
      props.history.push(slug)
   }

   return (
      <div className="lang" style={{"color": props.color}}>
         <div className="flex_row_lang pointer" onClick={()=>{changePage('/')}}>
            <div className="el">Ita</div>
            <div className="flag "><img src={ita_flag}/></div>
         </div>

         <div className="vl"/>

         <div className="flex_row_lang pointer" onClick={()=>{changePage('/en')}}>
            <div className="el">Eng</div>
            <div className="flag"><img src={usa_flag}/></div>
         </div>
         
      </div>
   )
}
export default withRouter(Languages)