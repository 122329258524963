import React, { useState } from "react"

const SelectInput = ({onChange,placeholder,label,fieldErrorMReg,default_value}) => {

   var nazione;

   return(
      <>
         <div>
            <div className="labelcss">{label}</div>
         </div>

         <select className={fieldErrorMReg ? ("fieldErrorMReg inputcss"): ("inputcss")} type="text"
               placeholder={"Seleziona "+placeholder}
               value={nazione}
               default_value={default_value}
               onChange={(event) => onChange(event.currentTarget.value)}
               required>
            <option value={""}>Seleziona {placeholder}</option>
            <option value={"Afghanistan"}>Afghanistan</option>
            <option value={"Albania"}>Albania</option>
            <option value={"Algeria"}>Algeria</option>
            <option value={"Andorra"}>Andorra</option>
            <option value={"Angola"}>Angola</option>
            <option value={"Arabia Saudita"}>Arabia Saudita</option>
            <option value={"Argentina"}>Argentina</option>
            <option value={"Armenia"}>Armenia</option>
            <option value={"Australia"}>Australia</option>
            <option value={"Austria"}>Austria</option>
            <option value={"Azerbaijan"}>Azerbaijan</option>
            <option value={"Bahamas"}>Bahamas</option>
            <option value={"Bahrain"}>Bahrain</option>
            <option value={"Bangladesh"}>Bangladesh</option>
            <option value={"Belgio"}>Belgio</option>
            <option value={"Belize"}>Belize</option>
            <option value={"Benin"}>Benin</option>
            <option value={"Bermuda"}>Bermuda</option>
            <option value={"Bhutan"}>Bhutan</option>
            <option value={"Bielorussia"}>Bielorussia</option>
            <option value={"Bolivia"}>Bolivia</option>
            <option value={"Bosnia ed Erzegovina"}>Bosnia ed Erzegovina</option>
            <option value={"Botswana"}>Botswana</option>
            <option value={"Brasile"}>Brasile</option>
            <option value={"Brunei"}>Brunei</option>
            <option value={"Bulgaria"}>Bulgaria</option>
            <option value={"Burkina faso"}>Burkina faso</option>
            <option value={"Burundi"}>Burundi</option>
            <option value={"Cambogia"}>Cambogia</option>
            <option value={"Camerun"}>Camerun</option>
            <option value={"Canada"}>Canada</option>
            <option value={"Capo Verde"}>Capo Verde</option>
            <option value={"Ciad"}>Ciad</option>
            <option value={"Cile"}>Cile</option>
            <option value={"Cina"}>Cina</option>
            <option value={"Cipro"}>Cipro</option>
            <option value={"Colombia"}>Colombia</option>
            <option value={"Comore"}>Comore</option>
            <option value={"Corea del Nord"}>Corea del Nord</option>
            <option value={"Corea del Sud"}>Corea del Sud</option>
            <option value={"Cossovo"}>Cossovo</option>
            <option value={"Costa d'avorio"}>Costa d'avorio</option>
            <option value={"Costa Rica"}>Costa Rica</option>
            <option value={"Croazia"}>Croazia</option>
            <option value={"CSI"}>CSI</option>
            <option value={"Cuba"}>Cuba</option>
            <option value={"Danimarca"}>Danimarca</option>
            <option value={"Ecuador"}>Ecuador</option>
            <option value={"Egitto"}>Egitto</option>
            <option value={"El Salvador"}>El Salvador</option>
            <option value={"Emirati Arabi Uniti"}>Emirati Arabi Uniti</option>
            <option value={"Eritrea"}>Eritrea</option>
            <option value={"Estonia"}>Estonia</option>
            <option value={"Etiopia"}>Etiopia</option>
            <option value={"Filippine"}>Filippine</option>
            <option value={"Finlandia"}>Finlandia</option>
            <option value={"Francia"}>Francia</option>
            <option value={"Gabon"}>Gabon</option>
            <option value={"Gambia"}>Gambia</option>
            <option value={"Georgia"}>Georgia</option>
            <option value={"Germania"}>Germania</option>
            <option value={"Ghana"}>Ghana</option>
            <option value={"Giamaica"}>Giamaica</option>
            <option value={"Giappone"}>Giappone</option>
            <option value={"Gibuti"}>Gibuti</option>
            <option value={"Giordania"}>Giordania</option>
            <option value={"Grecia"}>Grecia</option>
            <option value={"Groenlandia"}>Groenlandia</option>
            <option value={"Guatemala"}>Guatemala</option>
            <option value={"Guayana Francese"}>Guayana Francese</option>
            <option value={"Guinea"}>Guinea</option>
            <option value={"Guinea Bisseau"}>Guinea Bisseau</option>
            <option value={"Guinea equatoriale"}>Guinea equatoriale</option>
            <option value={"Guyana"}>Guyana</option>
            <option value={"Haiti"}>Haiti</option>
            <option value={"Honduras"}>Honduras</option>
            <option value={"India"}>India</option>
            <option value={"Indonesia"}>Indonesia</option>
            <option value={"Iran"}>Iran</option>
            <option value={"Iraq"}>Iraq</option>
            <option value={"Irlanda"}>Irlanda</option>
            <option value={"Islanda"}>Islanda</option>
            <option value={"Israele"}>Israele</option>
            <option value={"Italia"}>Italia</option>
            <option value={"Kazakistan"}>Kazakistan</option>
            <option value={"Kenya"}>Kenya</option>
            <option value={"Kirghizistan"}>Kirghizistan</option>
            <option value={"Kirghizistan"}>Kirghizistan</option>
            <option value={"Kuwait"}>Kuwait</option>
            <option value={"Laos"}>Laos</option>
            <option value={"Lesotho"}>Lesotho</option>
            <option value={"Lettonia"}>Lettonia</option>
            <option value={"Libano"}>Libano</option>
            <option value={"Liberia"}>Liberia</option>
            <option value={"Libia"}>Libia</option>
            <option value={"Liechtenstein"}>Liechtenstein</option>
            <option value={"Lituania"}>Lituania</option>
            <option value={"Lussemburgo"}>Lussemburgo</option>
            <option value={"Macedonia"}>Macedonia</option>
            <option value={"Madagascar"}>Madagascar</option>
            <option value={"Malawi"}>Malawi</option>
            <option value={"Malaysia"}>Malaysia</option>
            <option value={"Mali"}>Mali</option>
            <option value={"Malta"}>Malta</option>
            <option value={"Marocco"}>Marocco</option>
            <option value={"Mauritania"}>Mauritania</option>
            <option value={"Mauritius"}>Mauritius</option>
            <option value={"Messico"}>Messico</option>
            <option value={"Micronesia"}>Micronesia</option>
            <option value={"Moldavia"}>Moldavia</option>
            <option value={"Monaco"}>Monaco</option>
            <option value={"Mongolia"}>Mongolia</option>
            <option value={"Montenegro"}>Montenegro</option>
            <option value={"Mozambico"}>Mozambico</option>
            <option value={"Myanmar"}>Myanmar</option>
            <option value={"Namibia"}>Namibia</option>
            <option value={"Nepal"}>Nepal</option>
            <option value={"Nicaragua"}>Nicaragua</option>
            <option value={"Nigeria"}>Nigeria</option>
            <option value={"Norvegia"}>Norvegia</option>
            <option value={"Nuova Caledonia"}>Nuova Caledonia</option>
            <option value={"Nuova Zelanda"}>Nuova Zelanda</option>
            <option value={"Olanda"}>Olanda</option>
            <option value={"Oman"}>Oman</option>
            <option value={"Pakistan"}>Pakistan</option>
            <option value={"Panama"}>Panama</option>
            <option value={"Papua Nuova Guinea"}>Papua Nuova Guinea</option>
            <option value={"Paraguay"}>Paraguay</option>
            <option value={"Perù"}>Perù</option>
            <option value={"Polonia"}>Polonia</option>
            <option value={"Portogallo"}>Portogallo</option>
            <option value={"Qatar"}>Qatar</option>
            <option value={"Regno unito"}>Regno unito</option>
            <option value={"Repubblica Ceca"}>Repubblica Ceca</option>
            <option value={"Repubblica centrafricana"}>Repubblica centrafricana</option>
            <option value={"Repubblica del Congo"}>Repubblica del Congo</option>
            <option value={"Repubblica democratica del congo"}>Repubblica democratica del congo</option>
            <option value={"Repubblica Dominicana"}>Repubblica Dominicana</option>
            <option value={"Repubblica Slovacca"}>Repubblica Slovacca</option>
            <option value={"Romania"}>Romania</option>
            <option value={"Ruanda"}>Ruanda</option>
            <option value={"Russia"}>Russia</option>
            <option value={"Sahara occidentale"}>Sahara occidentale</option>
            <option value={"Senegal"}>Senegal</option>
            <option value={"Serbia"}>Serbia</option>
            <option value={"Sierra Leone"}>Sierra Leone</option>
            <option value={"Singapore"}>Singapore</option>
            <option value={"Siria"}>Siria</option>
            <option value={"Slovenia"}>Slovenia</option>
            <option value={"Somalia"}>Somalia</option>
            <option value={"Spagna"}>Spagna</option>
            <option value={"Sri Lanka"}>Sri Lanka</option>
            <option value={"Stati Uniti d'America"}>Stati Uniti d'America</option>
            <option value={"Sudafrica"}>Sudafrica</option>
            <option value={"Sudan"}>Sudan</option>
            <option value={"Suriname"}>Suriname</option>
            <option value={"Svezia"}>Svezia</option>
            <option value={"Svizzera"}>Svizzera</option>
            <option value={"Swaziland"}>Swaziland</option>
            <option value={"Tagikistan"}>Tagikistan</option>
            <option value={"Taiwan"}>Taiwan</option>
            <option value={"Tanzania"}>Tanzania</option>
            <option value={"Thailandia"}>Thailandia</option>
            <option value={"Togo"}>Togo</option>
            <option value={"Tunisia"}>Tunisia</option>
            <option value={"Turchia"}>Turchia</option>
            <option value={"Turkmenistan"}>Turkmenistan</option>
            <option value={"Ucraina"}>Ucraina</option>
            <option value={"Uganda"}>Uganda</option>
            <option value={"Ungheria"}>Ungheria</option>
            <option value={"Uruguay"}>Uruguay</option>
            <option value={"Uzbekistan"}>Uzbekistan</option>
            <option value={"Venezuela"}>Venezuela</option>
            <option value={"Vietnam"}>Vietnam</option>
            <option value={"Yemen"}>Yemen</option>
            <option value={"Zambia"}>Zambia</option>
            <option value={"Zimbabwe"}>Zimbabwe</option>
         </select>
      </>
   )
   
}

export default SelectInput