import React, {useState, useEffect} from 'react'
import './../css/user_registration.css';
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'

import { addField } from '../../redux/actions';
import Language from './../../components/common/language/lang'
import CP_Next_2 from './../../media/2_CP_Next.png'
import logo from './../../media/logo.svg'

import controlDataUReg from './../../components/userRegistration/URegData_control'
import SelectNazione from './../../components/common/dropDownMenu/eng/select_nazione'
import SelectSettore from './../../components/common/dropDownMenu/eng/select_settore'

const UserRegistration = (props) => {
   
   const changePage = slug => {
      props.history.push(slug+"-en")
   }

   const [FormData, setFormData] = useState({
      NomeUtente: props.form.NomeUtente,
      CognomeUtente: props.form.CognomeUtente,
      Azienda: props.form.Azienda,
      Email: props.form.Email,
      IndirizzoAzienda: props.form.IndirizzoAzienda,
      NazioneAzienda: props.form.NazioneAzienda,
      CittaAzienda: props.form.CittaAzienda,
      ProvinciaAzienda: props.form.ProvinciaAzienda,
      CAPAzienda: props.form.CAPAzienda,
      Telefono: props.form.Telefono,
      Settore: props.form.Settore
   })

   const [click,setClick] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         if(click){
            setClick((prev)=>prev=false)
         }
      }, 500)      
   }, [FormData])

   useEffect(() => {
      [nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)  
   }, [nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto])

   const submitMReg = () => {
      props.add("NomeUtente",FormData.NomeUtente);
      props.add("CognomeUtente",FormData.CognomeUtente);
      props.add("Azienda",FormData.Azienda);
      props.add("Email",FormData.Email);
      props.add("IndirizzoAzienda",FormData.IndirizzoAzienda);
      props.add("NazioneAzienda",FormData.NazioneAzienda);
      props.add("CittaAzienda",FormData.CittaAzienda);
      props.add("ProvinciaAzienda",FormData.ProvinciaAzienda);
      props.add("CAPAzienda",FormData.CAPAzienda);
      props.add("Telefono",FormData.Telefono);
      changePage('/machine-registration');
   }

   var nomeCorretto = false ,cognomeCorretto = false ,aziendaCorretto = false ,emailCorretto = false ,indirizzoCorretto = false ,nazioneCorretto = false ,cittaCorretto = false ,provinciaCorretto = false ,capCorretto = false ,telefonoCorretto;

   var pressInvio = (event) => {

      [nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)
      
      if (event.keyCode === 13){
         if (nomeCorretto && cognomeCorretto && aziendaCorretto && emailCorretto && indirizzoCorretto && nazioneCorretto && cittaCorretto && provinciaCorretto && capCorretto && telefonoCorretto){
            submitMReg()
         }else{
            setClick((prev)=>prev=true)
         }
      }
   }

   return (
      <div className="m_form" onKeyDown={(event)=>pressInvio(event)}>
         <div className="first_block">
            <a href="https://www.irinoxprofessional.com/eng/" target="_blank"><img className="logo" alt="logo" src={logo}/></a>
            <div className="langMReg"><Language  color={"#002445"}/></div>
         </div>
            
         <div className="twoblocksMReg">
            <div className="formMReg">
               <div className="formTitleMReg">USER'S DETAILS</div>
               <div className="columnsMReg">
                  

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)} 
                  
                  <div className="textInput "><TextInput fieldErrorMReg={!nomeCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           NomeUtente: valore
                        }))}
                        default_value={props.form.NomeUtente}
                     placeholder="your name" label="NAME*"/></div>

                  <div className="textInput "><TextInput fieldErrorMReg={!cognomeCorretto&&click ?  (true) : (false)}
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           CognomeUtente: valore
                        }))}
                        default_value={props.form.CognomeUtente}
                     placeholder="your surname" label="SURNAME*"/></div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)} 
                  
                  <div className="textInput "><TextInput fieldErrorMReg={!aziendaCorretto&&click ?  (true) : (false)}  
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Azienda: valore
                        }))}
                        default_value={props.form.Azienda}
                     placeholder="your company's name" label="COMPANY*"/></div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)} 
                  
                  <div className="textInput "><TextInput fieldErrorMReg={!emailCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Email: valore
                        }))}
                        default_value={props.form.Email}
                     placeholder="your e-mail" label="EMAIL*"/></div>
                  
                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                  <div className="textInput "><TextInput fieldErrorMReg={!indirizzoCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           IndirizzoAzienda: valore
                        }))}
                        default_value={props.form.IndirizzoAzienda}
                     placeholder="your company's address" label="ADDRESS*"/></div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                  <div className="textInput "><SelectNazione fieldErrorMReg={!nazioneCorretto&&click ?  (true) : (false)}
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           NazioneAzienda: valore
                        }))}
                        default_value={props.form.NazioneAzienda}
                     placeholder="Select your company's country" label="COUNTRY*"/></div>
                  
                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                  <div className="textInput "><TextInput fieldErrorMReg={!cittaCorretto&&click ?  (true) : (false)} 
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           CittaAzienda: valore
                        }))}
                        default_value={props.form.CittaAzienda}
                     placeholder="your company's town" label="TOWN*"/></div>

                  <div className="coupleAddr"> 
                     
                     {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                     <div className="textInput"><TextInput fieldErrorMReg={!provinciaCorretto&&click ?  (true) : (false)}
                        onChange={(valore)=>
                           setFormData((prev) => ({
                              ...prev,
                              ProvinciaAzienda: valore
                           }))}
                           default_value={props.form.ProvinciaAzienda}
                        placeholder="the province" label="PROVINCE*" couple={true}/></div>
                     
                     {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                     <div className="textInput"><TextInput fieldErrorMReg={!capCorretto&&click ?  (true) : (false)}
                        onChange={(valore)=>
                           setFormData((prev) => ({
                              ...prev,
                              CAPAzienda: valore
                           }))}
                           default_value={props.form.CAPAzienda}
                        placeholder="the zip code" label="ZIP CODE*" couple={true}/></div>
                  </div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                  <div className="textInput "><TextInput fieldErrorMReg={!telefonoCorretto&&click ?  (true) : (false)}
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Telefono: valore
                        }))}
                        default_value={props.form.Telefono}
                     placeholder="your phone number" label="PHONE NUMBER*"/></div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}

                  <div className="textInput "><SelectSettore
                     onChange={(valore)=>
                        setFormData((prev) => ({
                           ...prev,
                           Settore: valore
                        }))}
                     placeholder="Select the sector" label="SECTOR"/></div>
                  
               </div>
               
               <div className="tipMReg">(* mandatory fields)</div>
               <div className="buttonsMReg"> 
                  <div style={{"padding" : "0 5px 0 5px"}} className="pointer empty_button buttonMReg" onClick={() => {props.history.push("/en")}}>Go back to the home page</div>

                  {[nomeCorretto,cognomeCorretto,aziendaCorretto,emailCorretto,indirizzoCorretto,nazioneCorretto,cittaCorretto,provinciaCorretto,capCorretto,telefonoCorretto]=controlDataUReg(FormData)}               
                  <div style={{"padding" : "0 5px 0 5px"}}  className="pointer filled_button" onClick={() => { nomeCorretto && cognomeCorretto && aziendaCorretto && emailCorretto && indirizzoCorretto && nazioneCorretto && cittaCorretto && provinciaCorretto && capCorretto && telefonoCorretto
                      ? submitMReg() : (setClick((prev)=>prev=true))}}>Sign up</div>
               </div>
               
            </div>
            <div className="parteDestra">
               <img src={CP_Next_2} className="Cp3MReg"/>
               <div className="cpN2_descr">
                  <span className="cpN2_descr_title">Sign up to:</span><br/>
                  <div>
                     ▪ Constantly monitor your CP Next<br/>
                     ▪ Download your HACCP data<br/>
                     ▪ Control temperatures remotely in real time<br/>
                  </div>
                  
               </div>
            </div>
            
         </div>
      </div>
   )
}

const mapActionToProps = {
   add: (field,fieldValue) => addField(field,fieldValue)
}

export default connect(               
   state => { 
      return {form: state.form}
  },
   mapActionToProps
) (withRouter(UserRegistration))

const TextInput = ({onChange,placeholder,label,couple,fieldErrorMReg,default_value}) => (
   <>
      <div>
         <div className="labelcss">{label}</div>
      </div>      
      <input className={fieldErrorMReg ? ("fieldErrorMReg inputcss"): ("inputcss")}type="text"
         placeholder={"Enter "+placeholder}
         defaultValue={default_value}
         onChange={(event) => onChange(event.target.value)}
      />
   </>
)