import {createStore} from 'redux';
import reducer from './reducer';

//nuovo
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
   key: 'root',
   storage
}

const persistedReducer = persistReducer(persistConfig, reducer)

/* export default () => {
   let store = createStore(persistedReducer)
   let persistor = persistStore(store)
   return { store, persistor }
 } */

export const store = createStore(persistedReducer)

export const persistor = persistStore(store)

//nuovo

/* export const store = createStore(reducer)    vecchio
*/