import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './../css/landing.css';
import {withRouter} from 'react-router-dom'
import logo from './../../media/logo.png'
import Language from './../../components/common/language/lang'
import CP_Next_3 from './../../media/3_CP_Next.png'

import { addField } from '../../redux/actions';

import controlEmail from './../../components/landingPage/email-number_control.js'

const Landing = (props) => {
   
   const changePage = slug => {
      props.history.push(slug+"-en")
   }

   var input_error=[false,false];

   const [click,setClick] = useState(false);
   
   var mailError,pNError;

   const [FormData, setFormData] = useState({
      mail: props.form.Email,
      phoneNumber: props.form.Telefono
   })

   useEffect(() => {
      setTimeout(() => {
         if(input_error[0] || input_error[1]){
            input_error=[false,false]
         }
      }, 1000) 

   }, [input_error])

   useEffect(() => {
      setTimeout(() => {
         if(click){
            setClick((prev)=>prev=false)
         }
      }, 500)      
   }, [FormData])

   
   const submitLanding = (email,phn) => {
      props.add("Email", email);
      props.add("Telefono", phn);
      changePage('/machine-registration');
   }

   var pressInvio = (event) => {

      [mailError,pNError]=controlEmail(FormData)
      
      if (event.keyCode === 13){
         if (mailError && pNError){
            submitLanding(FormData.mail,FormData.phoneNumber)
         }else{
            setClick((prev)=>prev=true)
         }
      }
   }

   return (
      <div className="landing" onKeyDown={(event)=>pressInvio(event)}>
         <div className="first_block">
            <a href="https://www.irinoxprofessional.com/eng/" target="_blank"><img className="logo" alt="logo" src={logo}/></a>
            <Language color={"#FFFFFF"}/>
         </div>
         <div className="second_block">
            <div className="white_box">
               <div><img src={CP_Next_3} className="cp_next_3Img"/></div>
               <div className="cp_next_3Descrption">
               Register to C-CLOUD to control your Holding cabinet's temperatures in real time
               </div>
            </div>
            <div className="login_box">
               <div className="title">Insert your data</div>
               <input defaultValue={props.form.Email} type="text" placeholder="E-mail" className="elements inputText" onChange={(event) => {setFormData((prev) => ({
                  ...prev,
                  mail: event.target.value}))}}/>
               <input defaultValue={props.form.Telefono} type="text" placeholder="Phone Number" className="elements inputText" onChange={(event) => {setFormData((prev) => ({
                  ...prev,
                  phoneNumber: event.target.value}))}}/>

               {[mailError,pNError]=controlEmail(FormData)}

               <div className="elements pointer empty_button" onClick={() => {mailError && pNError ? (submitLanding(FormData.mail,FormData.phoneNumber)) : setClick((prev)=>prev=true)
               }}>Log in</div>

               {input_error=[!mailError,!pNError]}
               <p className="display_error">
                  <span style={input_error[0] && click ? ({"visibility": "visible"}) : ({"visibility": "hidden"})}>E-mail error</span>
                  <br/>
                  <span style={input_error[1] && click ? ({"visibility": "visible"}) : ({"visibility": "hidden"})}>Phone Number error</span>
               </p>

               <a className="elements pointer no_account" onClick={() => {changePage('/user-registration')}}>Don't have an account? Sign up!</a>
            </div>
         </div>
      </div>
   )
}

const mapActionToProps = {
   add: (field,fieldValue) => addField(field,fieldValue)
}

export default connect(               
   state => { 
      return {form: state.form}
  },
   mapActionToProps
) (withRouter(Landing))