import './App.css';
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";

import LayoutWrapper from './components/common/layout_wrapper'

import FooterIta from './components/common/footerIta';
import FooterEng from './components/common/footerEng';

import LandingIta from './pages/ita/landing'
import UserRegistrationIta from './pages/ita/user_registration'
import MachineRegistrationIta from './pages/ita/machine_registration'
import ThanksPageIta from './pages/ita/thanks_page';

import LandingEng from './pages/eng/landing'
import UserRegistrationEng from './pages/eng/user_registration'
import MachineRegistrationEng from './pages/eng/machine_registration'
import ThanksPageEng from './pages/eng/thanks_page';

import {Provider} from 'react-redux'

//prima di persist
//import {store} from './redux/store'
//prima di persist

//persist
import {store,persistor} from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
//persist

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>

      <HashRouter>
        <Switch>
          <Route exact path="/">
            <LayoutWrapper>
              <LandingIta/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/en">
            <LayoutWrapper>
              <LandingEng/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/user-registration">
            <LayoutWrapper>
              <UserRegistrationIta/>
              <FooterIta/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/user-registration-en">
            <LayoutWrapper>
              <UserRegistrationEng/>
              <FooterEng/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/machine-registration">
            <LayoutWrapper>
              <MachineRegistrationIta/>
              <FooterIta/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/machine-registration-en">
            <LayoutWrapper>
              <MachineRegistrationEng/>
              <FooterEng/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/thanks-page">
            <LayoutWrapper>
              <ThanksPageIta/>
            </LayoutWrapper>
          </Route>

          <Route exact path="/thanks-page-en">
            <LayoutWrapper>
              <ThanksPageEng/>
            </LayoutWrapper>
          </Route>

        </Switch>
      </HashRouter>

      </PersistGate>
    </Provider>
  );
}

export default App;
