import React, { useState } from "react"

const SelectInput = ({onChange,placeholder,label,fieldErrorMReg}) => {

   var settore;

   return(
      <>
         <div>
            <div className="labelcss">{label}</div>
         </div>

         <select className={fieldErrorMReg ? ("fieldErrorMReg inputcss"): ("inputcss")} type="text"
               placeholder={"Seleziona "+placeholder}
               value={settore}
               onChange={(event) => onChange(event.currentTarget.value)}
               required>
            <option value={""}>Seleziona il settore</option>
            <option value="Pasticceria">Pasticceria</option>
            <option value="Gelateria">Gelateria</option>
            <option value="Panificazione">Panificazione</option>
            <option value="Ristorazione">Ristorazione</option>
            <option value="Catering">Catering</option>
            <option value="Altro">Altro</option>
         </select>
      </>
   )
   
}

export default SelectInput